<template>
  <div>
    <div>
      <router-link to="doctorClose">停诊信息</router-link>
    </div>
    <div>
      <router-link to="outpatientSchedule">门诊排班</router-link>
    </div>
    <div>
      <router-link to="hospitalFee">住院清单</router-link>
    </div>
    <div>
      <router-link to="preFee">住院预交金</router-link>
    </div>
    <div>
      <router-link to="drug">药品查询</router-link>
    </div>
    <div>
      <router-link to="project">物价查询</router-link>
    </div>
    <div>
      <router-link to="queue">排队</router-link>
    </div>
    <div>
      <router-link to="pay">缴费记录</router-link>
    </div>
    <div>
      <router-link to="seeDoctor">就诊记录</router-link>
    </div>
    <div>
      <router-link to="caseCopyAdd">病例复印申请</router-link>
    </div>
    <div>
      <router-link to="caseCopy">病例复印列表</router-link>
    </div>
    <div>
      <router-link to="inspect">检验检查</router-link>
    </div>
    <div>
      <router-link to="survey?number=330281123456&type=1">
        门诊满意度
      </router-link>
    </div>
    <div>
      <router-link to="survey?number=330281123456&type=2">
        住院满意度
      </router-link>
    </div>
    <div>
      <router-link to="money">支付</router-link>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Index',
  }
</script>

<style scoped></style>
